import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import Footer from './components/footer/footer';
import HomePage from './components/homepage/homapage';
import Navbar from './components/navbar/navbar';
import ContactPage from './components/contactos/contactos';
import AboutUs from './components/nosotros/nosotros';

function App() {
  return (
    <Router>
          <I18nextProvider i18n={i18n}>

      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contactos" element={<ContactPage />} />
        <Route path="/aboutus" element={<AboutUs />} />

      </Routes>
      <Footer />
      </I18nextProvider>

    </Router>
    
  );
}

export default App;
