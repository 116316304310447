import React from 'react';
import { useTranslation } from 'react-i18next';
import './nosotros.css'
import fiamma from '../../assets/golfo_napoli.png'
const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="about-us-container">
            <div className="about-us-content">

    <h2 className="about-us-title">{t('aboutUs.title')}</h2>
    <p className="about-us-description">{t('aboutUs.description1')}</p>
    <p className="about-us-description">{t('aboutUs.description2')}</p>
    <p className="about-us-description">{t('aboutUs.description3')}</p>
    <p className="about-us-description">{t('aboutUs.description4')}</p>
    <p className="about-us-description">{t('aboutUs.description5')}</p>
    <p className="about-us-description">{t('aboutUs.description6')}</p>
    <p className="about-us-description">{t('aboutUs.description7')}</p>
    <img classname="about-us-fire" src={fiamma} alt='oven fire' />
  </div> 
   </div>

  );
}

export default AboutUs;
