import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.css'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import itTranslation from './locales/it.json';
import esTranslation from './locales/es.json';
import enTranslation from './locales/en.json'

i18n.use(initReactI18next).init({
  resources: {
    it: {
      translation: itTranslation,
    },
    es: {
      translation: esTranslation,
    },
    en: {
      translation: enTranslation,
    }
  },
  lng: 'es', // Set the default language to Italian
  fallbackLng: 'es', // Fallback to Italian if translation for the selected language is not available
  interpolation: {
    escapeValue: false,
  },
});
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
