import React from 'react';
import './contactos.css'
import { useTranslation } from 'react-i18next';


const ContactPage = () => {
  const { t } = useTranslation();

    // useEffect(() => {
    //     // Initialize the map when the component mounts
    //     initMap();
    //   }, []);

  return (
    <>
    <div className='contactos'>
        <div className='contactos-content'>
      
      <h1>{t('contactPage.contact')}</h1>
      <div>
      <h2>{t('contactPage.contactInfo')}</h2>
      <a href='tel:+34611328512' style={{textDecoration:'none'}}><p>{t('contactPage.phone')}</p></a>
<a href='mailto:info@reginamargherita.es' style={{textDecoration:'none'}}><p>{t('contactPage.email')}</p></a>
<p>{t('contactPage.address')}</p>

      </div>
      <div style={{margin: '40px'}}>
      <h2>{t('contactPage.location')}</h2>
      <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1599.0611852191007!2d-4.424088877447988!3d36.71962221353462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd72f7a9406dacfd%3A0xd8bbbb2a3def8927!2sRegina%20Margherita!5e0!3m2!1sit!2suk!4v1698830001993!5m2!1sit!2suk" style={{border:"0"}} allowfullscreen="" loading="lazy" rel="noopener" referrerpolicy="no-referrer-when-downgrade"></iframe>

      </div></div>
    </div>
    </>
  );
};

export default ContactPage;
