import React from 'react';
// import TripAdvisorWidget from './widget'
import './footer.css';
import { FaFacebook, FaInstagram, FaTwitter, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (<>
    {/* <div className='trip_advisor'><TripAdvisorWidget /></div> */}
    <div className="footer">
            

      <div className="footer-content">
        <div className="social-links">
          <a href="https://www.facebook.com/ReginaMargheritaES" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
          <a href="https://www.instagram.com/ReginaMargheritaES" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          <a href="https://www.twitter.com/ReginaMargheritaES" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
        </div>
        <div className="contact-info">
          <a href="mailto:info@reginamargherita.es"><FaEnvelope style={{color:'white'}}/></a>
          <p>Contacto: info@reginamargherita.es</p>
        </div>
      </div>
      <p className="copy-right">© 2023 Regina Margherita ES. Todos los derechos reservados.</p>
    </div></>
  );
}

export default Footer;
