import React, {  useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import logo from '../../assets/onlyoven.png';
import Name from '../../assets/Name.png';
import { useTranslation } from 'react-i18next';
import italy from '../../assets/italy.jpg';
import spain from '../../assets/spanish.jpg';
import english from '../../assets/english.jpg';
import menu from '../../assets/menu-website.pdf';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { t, i18n } = useTranslation();


  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeMenu = () => {
    setIsNavOpen(false);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };


  
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/" className="navbar-link">
            <img src={logo} alt="forno" style={{ height: '35px', width: '35px', position: 'relative', left: '-3px' }} />
          </Link>
          <img src={Name} alt="forno" style={{ height: '35px', width: '150px', position: 'relative', left: '5px' }} />
        </div>

        <div className={`navbar-toggle ${isNavOpen ? 'active' : ''}`} onClick={handleNavToggle}>
          <span className="navbar-toggle-icon"></span>
        </div>
      </div>

      <ul className={`navbar-menu ${isNavOpen ? 'active' : ''}`}>
        {/* Language Selector */}
        <div className="language-selector">
          <button className={`language-button ${i18n.language === 'it' ? 'active' : ''}`} onClick={() => { changeLanguage('it'); closeMenu(); }}>
            <img src={italy} alt="Italiano" />
          </button>
          <button className={`language-button ${i18n.language === 'es' ? 'active' : ''}`} onClick={() => { changeLanguage('es'); closeMenu(); }}>
            <img src={spain} alt="Español" />
          </button>
          <button className={`language-button ${i18n.language === 'en' ? 'active' : ''}`} onClick={() => { changeLanguage('en'); closeMenu(); }}>
            <img src={english} alt="English" />
          </button>
        </div>

        {/* Navbar Items */}
        <li className="navbar-item">
          <Link to="/" className="navbar-link" onClick={closeMenu}>
            {t('navbar.home')}
          </Link>
        </li>
        <li className="navbar-item">
          <a href={menu} target="_blank" className="navbar-link" rel="noreferrer" onClick={closeMenu}>
            {t('navbar.menu')}
          </a>
        </li>
        <li className="navbar-item">
          <Link to="/aboutus" className="navbar-link" onClick={closeMenu}>
            {t('navbar.about')}
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/contactos" className="navbar-link" onClick={closeMenu}>
            {t('navbar.contact')}
          </Link>
        </li>
      </ul>


      
    </nav>
  );
};

export default Navbar;
